.firebase-wrapper {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.app-name {
  font-family: Roboto-Medium;
  color: #000000;
  font-size: 40px;
  font-weight: 500;
  line-height: 47px;
  font-family: Roboto-Medium;
}

.heading {
  font-size: 38px;
  text-align: center;
}

.login-error {
  color: red;
  font-size: 0.8rem;
}

.signin-container {
  height: 100vh;
}

.signin-body {
  height: calc(100% - 5rem);
  position: relative;
  background-image: url('../../public/images/Login-Background.png');
  font-family: Roboto-Regular;
  background-size: cover;
}