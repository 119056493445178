.selected-project {
  color: #F00053;
  text-decoration: underline;
  font-family: Roboto-Bold;
}

.row-image {
  display: flex;
  justify-content: flex-end;
}

.projectWrapper {
  padding-top: 20px;
  color: #a2a2a2;
  display: flex;
  min-height: 320px;
  flex-direction: column;
}
.projectWrapper .project-list:empty + .project-create-text {
  align-self: center;
  margin: auto 0;
  padding-bottom: 45px;
  font-size: 12px;
}

.projectWrapper .project-list:not(:empty) + .project-create-text {
  display: none;
}

.projectWrapper h5 {
  color: #a2a2a2;
  margin-bottom: 3px;
}

.projectTitle {
  font-size: 13px;
  font-family: Roboto-Medium;
  color: #7e8486;
}

.MuiInputLabel-outlined {
  text-transform: none;
}

.project-success-message {
  margin-top: 2%;
  font-size: 16px;
  color: #000000;
  font-weight: 600;
}

.delete-icon {
  margin: 0 20px 0 10px;
  height: 16.5px;
  width: 14.72px;
}

.client-domain-helpertext {
  margin-bottom: 2px;
  color: #3a4245;
  font-size: 13px;
  padding-left: 10px;
}

.new-project-label {
  padding-left: 10px;
  margin-top: 13px;
}
.required {
  color: #ff0000;
}

.panel-inactive > .ant-collapse-item > .ant-collapse-header {
  color: rgba(58, 66, 69, 0.35) !important;
}

.bottom-note {
  color: #7e8486;
  font-size: 13px;
}
.row {
  display: flex;
  justify-content: flex-start;
}
.create-project-btn,
.submit-btn,
.download-btn,
.error-popup-close,
.error-popup .ant-modal-footer button {
  background-color: #00aebd !important;
  height: 44px;
  width: 100%;
  color: #ffffff !important;
  font-family: Roboto-Bold;
  border: none;
  margin: 15px 0;
  padding: 0 15px;
  cursor: pointer;
  font-size: 16px;
  border-radius: 0px;
  outline: none;
}

.error-popup .ant-modal-footer button {
  width: 45%;
}

.project-modal-footer {
  flex-direction: column;
  margin-bottom: 44px;
  display: flex;
  text-align: end;
}

.share-label {
  color: #00aebd;
  font-size: 13px;
  padding: 3.25px;
}

.share-icon {
  height: 15.5px;
  width: 15.5px;
}

.arrowDownIcon {
  height: 11.5px;
  width: 21.5px;
}

.share-wrapper {
  text-indent: 0px;
  display: flex;
  align-items: center;
}

.confirm-modal-footer {
  margin: 28px 0;
}

.confirm-modal-footer .ant-btn-primary,
.share-modal-footer .ant-btn-primary {
  background-color: #00aebd;
  border-color: #00aebd;
  height: 44px;
  width: 160px;
  font-family: Roboto-Bold;
}

.confirm-modal-footer .ant-btn,
.share-modal-footer .ant-btn {
  height: 44px;
  width: 160px;
  font-size: 16px;
}

.confirm-modal-footer .ant-btn-background-ghost,
.share-modal-footer .ant-btn-background-ghost {
  color: #00aebd;
  border: none;
}

.confirm-icon {
  height: 30px;
  width: 30px;
  margin: 45px 0 16.13px 0;
}

.confirm-modal-body {
  text-align: center;
  font-family: "Roboto-Regular";
  font-size: 16px;
}

.share-modal-footer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.share-modal-footer .ant-btn-primary {
  width: 100%;
  color: #ffffff;
  margin: 20px 0 10px 0;
}

.remove-icon {
  color: #00aebd;
  font-size: 13px;
  cursor: pointer;
  padding-left: 11px;
}

.confirm-icon div {
  font-size: 16px;
}

.email-address-input {
  font-size: 15px;
  color: #3a4245;
  font-family: "Roboto-Regular";
  padding: 16px;
  width: 100%;
  border: 0.5px solid #7e8486;
  border-radius: 2px;
  outline: none;
}

.email-address-input:focus {
  border: 2px solid #a2a2a2 !important;
}

.input-error {
  border: 1px solid #d66b6b;
}

button:disabled{
  opacity: 0.5;
  cursor:default;
}

.ant-collapse-content-active{
  transform: rotate(180);
}

.helpful-resources, .choose-project{
      font-family: Roboto-Black;
      font-size: 20px;
      /* font-weight: 900; */
      line-height: 24px;
      padding-top: 44px;
      height: 100%;   
      color: #000000;   
}

.choose-project div{
  font-size: 14px;
  letter-spacing: 0;
  line-height: 16px;
  font-family: Roboto-Regular;
}

.choose-project{
  padding-top:81px !important;
}


.filter-container{
  padding: 16px 60px !important;
  cursor: pointer;
}

.create-project-button{
  padding: 10px;
  text-align: right;
}

.filter-popup .ant-modal-content, .filter-popup .ant-collapse-header, .filter-popup .ant-collapse-content > .ant-collapse-content-box>div{
  border: 0.5px solid #000000 !important;
  background-color: #000000 !important;
  box-shadow: 0 2px 8px 0 rgb(0 0 0 / 50%) !important;
  color: #FFFFFF !important;
}

.filter-popup .ant-modal-body{
 padding: 0!important;
}

.filter-title{
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 15px;
  font-family: Roboto-Medium;
}

.filter-closeIcon{
  width:13.44px;
  margin-right:16.7px;
  cursor: pointer;
}

.filter-section{
  border-bottom: 2px solid rgb(47, 47, 47);
  padding: 15px 10px;
}

.filter-apply-button {
  border: 1px solid #FFFFFF !important;
  margin: 20px 30.5px;
}

.filter-popup input[type='text'] {
border:none;
border-bottom:1px solid #FFFFFF;
background-color: #000000;
}


.filter-searchIcon{
 width: 16.58px;
}

.checkbox-container{
  display: flex;
  flex-direction: column;
}

.checkbox-container label{
  color:#FFFFFF;
  margin: 10px;
}

.filter-popup input[type='checkbox'] {
  border:1px solid #FFFFFF;
  background-color: #000000;
  }

  .filter-popup .ant-collapse{
    border: none;
  }
