.MuiOutlinedInput-input {
  padding: 9.5px 4px !important;
}
.ant-table-thead > tr > th {
  padding: 10px !important;
}
.ant-table-tbody > tr > td {
  padding: 9px !important;
  border-bottom: none !important;
}
.sync-icon {
  font-size: 28px;
  position: absolute;
  top: -40px;
  right: 0;
}
.upload-history-wrapper {
  position: relative;
  width: 100%;
}
.upload-history-wrapper .ant-table table,
.upload-history-wrapper .ant-table-placeholder {
  border-radius: 0;
  border-top: none;
}

.upload-history-wrapper .ant-table table tr:nth-child(odd) {
  background-color: #f2f2f2;
}

.upload-history-wrapper .ant-table-placeholder {
  color: #7e8486;
  font-size: 13px;
  border-bottom: none;
  min-height: 120px;
  justify-content: center;
  align-items: center;
  display: flex;
}
.ant-select-search__field__wrap input {
  color: #a2a2a2;
}
.ant-select-selection--multiple {
  padding: 7px 5px;
}
.ant-empty-description {
  color: #a2a2a2;
}
.upload-history-wrapper .ant-table-thead > tr > th {
  padding: 20px 10px !important;
  color: #000000;
  border: none;
  background: white;
  font-size: 12px;
  font-family: Roboto-Regular;
}

.ant-select-selection--multiple {
  border: 1px solid #a2a2a2;
}

.ant-select-selection__choice {
  border-radius: 11px !important;
  background: #232121 !important;
  color: #fff !important;
  border: transparent !important;
}
.ant-select-selection__choice__remove {
  color: #fff !important;
}

.pointer {
  cursor: pointer;
}

.Upload-toggle-btn {
  font-size: 13px;
  margin: 18px 0px 15px;
  width: 140px;
  display: flex;
  justify-content: space-between;
}

.Upload-toggle-btn .ant-radio-button-wrapper-checked {
  font-family: Roboto-Medium;
  font-weight: 500;
  box-shadow: none;
}

.ant-radio-group-solid
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled),
.ant-radio-group-solid
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
    border-radius: 16.5px;
    background-color: #000000;
    border-color: #000000;
}

.ant-radio-button-wrapper:not(:first-child)::before{
  background: none !important;
}

.ant-radio-button-wrapper:hover {
  color: #000000;
}
.ant-radio-button-wrapper {
  border: 0.5px solid #000000 !important;
  border-radius: 16.5px !important;
  background-color: #FFFFFF;
}

.searchTermHeading, .keyword-notes span, .bulk-desc-header ,.title-notifications,
.create-project-title {
  color: #000000;
  font-family: Roboto-Bold;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: -0.03px;
  line-height: 19px;
  padding-top: 30px;
}

.grid-label, .new-project-label  {
  color: #000000;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 14px;
  text-transform: uppercase;
  padding-left: 8px;
}
.bulk-upload-wrapper .grid-label {
  padding-left: 0px;
}

.bulk-upload-desc-wrapper ul {
  padding-bottom: 20px;
}

.success-icon {
  height: 50px;
  width: 50px;
  padding-bottom: 10px;
}

.prj-success-icon {
  height: 50px;
  width: 50px;
  padding-bottom: 10px;
}

.prj-loader-container {
  height: 8rem;
  display: flex;
  align-items: center;
}

.upload-more-button {
  color: #F00053;
  padding-top: 10px;
  cursor: pointer;
}

.add-keyword-wrapper li {
  list-style-type: none !important;
}

.half-width-wrapper {
  display: flex;
  justify-content: space-between;
  padding-bottom: 6px;
}

.ant-pagination-simple-pager .ant-pagination-slash {
  visibility: hidden;
}

.ant-pagination-simple-pager .ant-pagination-slash:after {
  content: "of";
  margin-left: 0;
  visibility: visible;
}

.ant-pagination-simple li {
  padding: 0px !important;
  margin: 0px !important;
}

.ant-pagination-simple .ant-pagination-simple-pager input {
  border: none !important;
  margin: 0px !important;
  padding: 0px !important;
  width: 13px;
}

.ant-table-pagination.ant-pagination {
  margin: 30px 0 0 0 !important;
}

.ant-pagination-simple .ant-pagination-simple-pager {
  font-size: 13px;
  color: #7e8486;
}

li::marker {
  font-size: 10px;
}

.manual-upload-footer{
display: flex;
flex-direction: column;
align-items: flex-end;
padding-bottom: 90px;
}

.drop-down-icon{
  width: 16px;
  cursor: pointer;
  margin-right: 14px;
}