.ant-popover-inner-content {
  max-height: 300px;
  overflow: auto;
}

.updates-notification-heading {
  font-size: 13px;
  margin-bottom: 0 !important;
  color: #3a4245;
}

.notification-icon {
  height: 6px;
  width: 6px;
}
.empty-notifications-wrapper {
  border-bottom: 1px solid #e8e8e8;
}

.notification-modal > .ant-modal-content,
.create-project-wrapper {
  font-family: "Roboto-Regular";
}

.error-popup > .ant-modal-content {
  min-width: fit-content;
  font-family: "Roboto-Regular";
}

.notification-modal > .ant-modal-content > .ant-modal-body {
  max-height: 200px !important;
  overflow: auto !important;
  padding: 0px;
}

.notification {
  cursor: pointer;
  border-bottom: 1px solid #e8e8e8;
  color: #a2a2a2;
  padding: 20px;
  display: flex;
  align-items: baseline;
}
.unread-notification {
  color: #3a4245;
}

.paragraph-child {
  color: #3a4245;
}

.notification-date {
  font-size: 10px;
  color: #7e8486;
}

.notification-title {
  font-weight: 700;
  color: black;
}
.clear-btn {
  font-size: 13px;
  color: #F00053;
  text-align: center;
  padding: 5px;
  cursor: pointer;
}

.circular-progress {
  min-height: 50px;
}

.notification-modal .closeIcon {
  height: 16px;
  width: 16px;
}

.closeIcon {
  height: 24px;
  width: 24px;
  cursor: pointer;
}

.notification-body {
  padding: 0 12px;
}
