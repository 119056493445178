/* @import '~antd/dist/antd.css'; */
.header {
  height: 91px;
  display: flex;
  align-items: center;
  /* max-width: 1338px; */
  margin: 0 auto;
  justify-content: end;
}

/* .inner-header {

} */
.outer-wrapper {
  min-height: calc(100vh - 138px);
}

.MuiGrid-container.full-wrapper {
  color: #000000 !important;
}

.MuiGrid-container.full-wrapper>.MuiGrid-item {
  padding: 0 !important;
}

.projects-heading {
  color: #a2a2a2;
}

.margin-appbutton-ss {
  margin-left: 57px;
}

.project-list {
  max-height: 420px;
  overflow: auto;
}

.margin-t2r {
  margin-top: 2rem;
}

.project-list .ant-collapse>.ant-collapse-item>.ant-collapse-header {
  display: flex;
  flex-direction: column;
  padding: 19px 50px 0px 0px !important;
  font-size: 16px;
  color: #3a4245;
}

.project-list .ant-collapse>.ant-collapse-item>.ant-collapse-header .ant-collapse-arrow {
  margin-top: 10px;
  position: absolute;
  top: inherit;
  left: inherit;
}

/* .project-list
  .ant-collapse
  > .ant-collapse-item
  > .ant-collapse-header
  .ant-collapse-extra {
  margin-left: auto;
} */

.project-list .ant-collapse>.ant-collapse-item {
  padding-bottom: 19px;
}

.content-style p {
  background-color: #363636;
}

.logo {
  font-size: 24px;
  color: #3a4245;
}

.signout-btn {
  position: absolute;
  top: 9px;
  right: 3px;
  font-weight: 600;
  color: #fff !important;
}

.fixed-container {
  /* min-height: 100vh; */
  background: #f2f6f9;
}

.bottom-border {
  border-bottom: 1px solid #979797;
}

.no-options {
  color: white;
  text-align: center;
}

.expansion-panel {
  background: transparent !important;
  color: white !important;
}

.color-white {
  color: white !important;
}

.left-wrapper {
  position: fixed;
  max-width: 350px;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.left-wrapper.box-wrapper {
  min-height: calc(100vh - 425px);
  margin-right: 40px;
  width: 100% !important;
  min-width: inherit;
  padding: 20px;
}

.popover-filter {
  background-color: #000 !important;
  position: fixed  !important;
  left: 0  !important;
  max-width: 100% !important;
  height: 60vh;
}

.box-wrapper {
  border: 0.3px solid #000000;
  min-height: calc(100vh - 300px);
  position: relative;
}

.layout-style.box-wrapper {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 376px);
  padding-bottom: 45px;
}

.MuiTypography-body1,
.MuiTypography-body1>div {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.count-wrapper {
  display: flex;
}

.count-wrapper>div {
  display: flex;
  flex-direction: column;
  width: 69px;
}

.right-wrapper {
  width: 50%;
}

.right-top-wrapper {
  display: flex;
  margin: 0 30px 45px 0;
  padding: 0 30px 15px 0;
  justify-content: space-around;
}

.middle-heading, .count-heading {
  font-size: 12px;
  display: flex;
  line-height: 14px;
}

@media only screen and (max-width: 960px) {}



.tooltip-icon{
  height: 12px;
  margin-left: 10px;
  cursor: pointer;
  margin-bottom: 3px;
}

.middle-content, .project-label {
  font-size: 20px;
  font-weight: 900;
  letter-spacing: 0.6px !important;
  line-height: 24px;
  font-family: Roboto-Medium;
}

.container-center {
  display: flex;
  align-items: center;
  height: 100%;
}

.m-t-1 {
  margin-top:  0.5rem !important;
}

.collapse-padding {
  padding: 7px 10px 0 0;
}

.count{
  font-size: 40px;
  font-weight: 900;
  letter-spacing: -0.08px;
  line-height: 47px;
}

.bg-color {
  background: white;
}

.colorblack {
  color: black;
}

.filter-tag {
  padding: 10px 10px;
  border-radius: 20px;
  border-color: black;
  color: black;
  margin-top: 5px;
}

.clear-button-style {
  text-decoration: underline !important;
  color: #F00053 !important;
}

.client-projects {
  position: relative;
  flex: 1;
}

.client-label,
.share-users-title,
.existing-user-title,
.ant-table-column-title,
.error-popup-error-label,
.upload-data-label
{
  text-transform: uppercase;
  margin-bottom: 5px;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 14px;
}

.client-label:empty:before {
  content: "CLIENT";
  display: block;
}

.keyword-summary-wrapper {
  align-self: flex-end;
  min-width: 70%;
  padding-left: 20px;
}

.project-label:empty:before {
  content: "Projects";
  display: block;
}

.icon-group {
  width: 50px;
  margin-bottom: 15px;
}


.MuiTab-textColorPrimary {
  color: #a2a2a2 !important;
}

.PrivateTabIndicator-colorPrimary-26 {
  background-color: #a2a2a2 !important;
}

.PrivateTabIndicator-colorSecondary-27 {
  background-color: rgb(0, 174, 189) !important;
}

.icon-circle {
  position: absolute;
  bottom: -10px;
  right: -10px;
  cursor: pointer;
}

.common-header-wrapper {
  max-width: 1440px;
  margin: 0 auto;
}

.header-right-wrapper {
  position: relative;
}

.notification-wrapper {
  cursor: pointer;
  min-width: 58px;
}

.alert-label {
  font-size: 13px;
  color: #000000;
}

.notification-bell-icon {
  height: 18px;
  width: 15.62px;
  margin: 4px;
}

.notification-badge span {
  font-size: 9px;
  font-family: Roboto-Bold;
  height: 14px;
  width: 14px;
  min-width: 12px;
  background-color: #F00053;
  top: 7px;
  right: 4px;
}

.alpha-version {
  color: #a2a2a2;
}

.location-track {
  color: #F00053;
}

.font-bold-500 {
  font-weight: 500 !important;
}

.font-bold-700 {
  font-weight: 700 !important;
}

.font-bold-900 {
  font-weight: 900 !important;
}

@media only screen and (min-width: 960px) {
  .MuiGrid-grid-md-4 {
    max-width: 27.333333% !important;
    min-width: 265px;
  }
}

@media only screen and (min-width: 960px) and (max-width: 1100px) {
  /* .MuiGrid-container.full-wrapper,
  .inner-header,
  .inner-footer {
    width: 900px;
  } */

  .right-top-wrapper {
    margin: 20px 10px 45px 10px;
    padding: 0 10px 15px;
  }

  .MuiGrid-container.full-wrapper>.MuiGrid-item {
    margin-left: 13px;
  }
}

@media only screen and (max-width: 960px) {

  .MuiGrid-container.full-wrapper,
  .inner-header,
  .inner-footer {
    width: 100%;
    padding: 0 20px;
  }

  .MuiGrid-grid-xs-2 {
    max-width: 100%;
    min-width: 100%;
  }

  .left-wrapper.box-wrapper {
    margin-right: 0;
    max-width: 100%;
    margin-bottom: 40px;
  }

  .right-top-wrapper {
    margin-top: 50px;
  }

  .MuiTypography-body1 {
    min-height: inherit;
  }

  .count-wrapper {
    flex-wrap: wrap;
  }

  .create-project-wrapper {
    max-width: 66%;
  }

  .upload-tabs .MuiTab-root {
    width: 50%;
  }

  .bulk-upload-wrapper {
    padding-right: 0px;
  }
}

.loading-message {
  margin-top: 5%;
  color: #7e8486;
  font-size: 13px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.center {
  text-align: center;
}

.jupiter-btn {
  margin-top: 25% !important;
  color: #fff !important;
  background: #000 !important;
}

.project-empty-text {
  margin-top: 146px;
  align-self: center;
  text-align: center;
  font-size: 13px;
  color: #7e8486;
}

.notification-item {
  padding-bottom: 6px;
  font-size: 13px;
}

div.ant-typography {
  margin-bottom: 0;
}

.ant-typography ul li {
  list-style-type: disc;
  color: #000;
  font-size: 13px;
}

a, a:focus, a:hover {
  text-decoration: underline !important;
  color: #F00053 !important;
}

.uat-banner {
  background-color: #f5cc52;
  text-align: center;
  font-family: Roboto-Regular;
  color: #0000;
  font-size: 14px;
  line-height: 16px;
}

.uat-banner .ant-alert-message a {
  color: #000;
  text-decoration: underline;
}

.uat-alert-icon {
  height: 16px;
  margin-right: 14.73px;
  width: 3.27px;
}


.ant-alert.ant-alert-no-icon {
  padding: 16px 15px;
}

.padding-b10 {
  padding-bottom: 10px;
}

.padding-b2r {
  padding-bottom: 2rem;
}

.left-inner-container{
  border-bottom: 1px solid #979797;
  padding: 10px 25px 25px 60px;
}

.margin-bottom-50 {
  margin-bottom: 50px !important;
}

.display-column {
  display: flex;
  flex-direction: column;
}

.margin0 {
  margin: 0 !important;
}

.margin-top30 {
  margin-top: 30px !important;
}

.leftbottom-inner-container {
  padding: 10px 25px 25px 60px;
}

.leftbottom-inner-container  img{
  width: 18px;
  margin-right: 15px;
}

.leftbottom-inner-container  span{
  font-family: Roboto-Medium;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 15px;  
}

.left-inner-container img{
  width: 18px;
  margin-right: 15px;
}

.left-inner-container span{
  font-family: Roboto-Medium;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 15px;  
}
