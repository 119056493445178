.MuiExpansionPanelSummary-content {
  margin: 0 !important;
}

.collapse-panel {
  color: #a2a2a2 !important;
  position: unset !important;
  border: none !important;
}

.ant-collapse>.ant-collapse-item>.ant-collapse-header {
  color: #a2a2a2;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

.ant-collapse>.ant-collapse-item:last-child,
.ant-collapse-header {
  border-radius: 0 !important;
  margin-bottom: 1px;
}

.ant-collapse-content {
  border-top: none !important;
}

.share-popup>.ant-modal-content>.ant-modal-header,
.share-popup>.ant-modal-content>.ant-modal-footer {
  color: #a2a2a2;
  border-top: transparent !important;
  border-bottom: transparent !important;
}

.share-popup>.ant-modal-content {
  min-width: 450px;
}

.outer-wrapper .ant-collapse-item:last-child>.ant-collapse-content {
  border-radius: 0;
}

.outer-wrapper .ant-collapse-content>.ant-collapse-content-box>div>div {
  padding: 14px 0 7px 10px;
  height: auto;
  display: flex;
  text-align: left;
  justify-content: space-between;
  text-indent: 0;
  align-items: center;
  font-size: 15px;
  color: #3a4245;
  overflow-wrap: anywhere;
  line-height: 15px;
  font-family: "Roboto-Medium";
}

.outer-wrapper .ant-collapse-content>.ant-collapse-content-box span .anticon-delete {
  padding-right: 20px;
  padding-top: 3px;
  margin-left: auto;
  display: block;
}

.share-popup .ant-modal-body {
  padding: 0 60px;
}

.share-popup .ant-modal-body .user-title,
.share-popup .ant-modal-body .user-list {
  padding: 7px 15px;
  min-height: inherit;
  margin-top: 0;
}

.share-popup .ant-modal-body .user-title {
  color: #e6e6e6;
  font-size: 16px;
}

.share-popup {
  min-width: 500px;
}

.share-popup .ant-modal-body .user-list {
  font-size: 15px;
  color: #3a4245;
  margin-bottom: 20px;
  border: 0.5px solid #7e8486;
}

.anticon-question-circle {
  color: #a2a2a2 !important;
}

.ant-collapse {
  border-radius: 0 !important;
  font-size: 14px !important;
}

.project-list>.ant-collapse {
  background-color: white !important;
}

.MuiTypography-body1 {
  padding: 3px;
  font-size: 12px !important;
  min-height: 335px;
}

.MuiExpansionPanelDetails-root {
  display: grid !important;
}

.count-label {
  font-size: 13px;
  letter-spacing: -0.03px;
  line-height: 15px;
}

.count {
  margin-bottom: 0;
}

.share-icon {
  color: #a2a2a2;
}

.MuiCircularProgress-colorPrimary {
  color: #a2a2a2 !important;
  margin-left: 47% !important;
  display: flex !important;
  align-items: center;
}

.modal-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content {
  padding: 0 30px 30px 30px;
  overflow: auto;
  min-height: 350px;
  max-height: calc(100vh - 258px);
}

.MuiFormControl-root {
  padding: 10px !important;
}

form .MuiOutlinedInput-notchedOutline,
.ant-modal .MuiOutlinedInput-notchedOutline,
.box-wrapper .MuiOutlinedInput-notchedOutline {
  border-color: #a2a2a2;
}

form .MuiInputLabel-outlined,
.ant-modal .MuiInputLabel-outlined,
.box-wrapper .MuiInputLabel-outlined {
  color: #000000 !important;
  font-size: 15px !important;
  padding-left: 5px;
  font-family: "Roboto-Regular";
}

form .MuiFormControl-root input,
.ant-modal .MuiFormControl-root input,
.box-wrapper .MuiFormControl-root input {
  padding: 14.25px 15.25px !important;
  border-radius: 2px;
  color: #000000 !important;
  font-family: Roboto-Regular;
  font-size: 13px;
  font-weight: 300;
}

.download-wrapper .MuiInputLabel-formControl {
  top: -7px;
}

form .MuiInputBase-multiline,
.ant-modal .MuiInputBase-multiline,
.box-wrapper .MuiInputBase-multiline {
  margin-top: 0 !important;
  border: none;
  display: flex;
  padding: 12px;
}

form .MuiOutlinedInput-inputMultiline,
.ant-modal .MuiOutlinedInput-inputMultilin,
.box-wrapper .MuiOutlinedInput-inputMultilin {
  height: 75px !important;
  overflow: auto !important;
  float: left;
  align-self: flex-start;
  padding: 0 !important;
  font-size: 14px;
}

form .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline,
.box-wrapper .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline,
.ant-modal .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #a2a2a2;
}

/* .MuiFormLabel-root.Mui-focused {
  display: none;
} */

.MuiInput-underline:after {
  border: none !important;
}

form .MuiFormControl-root:hover label,
.box-wrapper .MuiFormControl-root:hover label,
.ant-modal .MuiFormControl-root:hover label {
  color: rgba(139, 139, 139, 0.8) !important;
}

form .MuiInputLabel-outlined.MuiInputLabel-shrink,
.box-wrapper .MuiInputLabel-outlined.MuiInputLabel-shrink,
.ant-modal-body .MuiInputLabel-outlined.MuiInputLabel-shrink {
  transform: translate(18px, 7px) scale(0.75);
}

form.MuiInputLabel-outlined,
.box-wrapper .MuiInputLabel-outlined,
.ant-modal-body.MuiInputLabel-outlined {
  transform: translate(14px, 22px) scale(1);
}

::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background-color: #d0d2d3;
  border-radius: 3px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background-color: rgba(126, 132, 134, 0.75);
  border-radius: 3px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background-color: rgba(126, 132, 134, 0.75);
}

form .MuiAutocomplete-root .MuiIconButton-root,
.box-wrapper .MuiAutocomplete-root .MuiIconButton-root,
.ant-modal-body .MuiAutocomplete-root .MuiIconButton-root {
  transform: rotate(-90deg);
}

form .MuiAutocomplete-root .MuiAutocomplete-popupIndicatorOpen,
.box-wrapper .MuiAutocomplete-root .MuiAutocomplete-popupIndicatorOpen,
.ant-modal-body .MuiAutocomplete-root .MuiAutocomplete-popupIndicatorOpen {
  transform: rotate(360deg);
}

form .MuiFormHelperText-root.Mui-error,
.box-wrapper .MuiFormHelperText-root.Mui-error,
.ant-modal-body .MuiFormHelperText-root.Mui-error {
  color: #d66b6b !important;
  padding-left: 10px;
  font-size: 10px;
}

form .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline,
.box-wrapper .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline,
.ant-modal-body .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
  border-color: #d66b6b;
}

form .MuiInputBase-multiline .MuiOutlinedInput-notchedOutline,
.box-wrapper .MuiInputBase-multiline .MuiOutlinedInput-notchedOutline {
  height: 105px;
  overflow: auto;
}

form .MuiFormLabel-root.Mui-error,
.box-wrapper .MuiFormLabel-root.Mui-error,
.ant-modal-body .MuiFormLabel-root.Mui-error {
  color: #d66b6b;
}

form .ant-select-selection--multiple .ant-select-selection__placeholder,
.box-wrapper .ant-select-selection--multiple .ant-select-selection__placeholder,
.ant-modal-body .ant-select-selection--multiple .ant-select-selection__placeholder {
  color: #a2a2a2;
  font-size: 12px;
}

.share-popup .MuiOutlinedInput-root {
  border: none;
}

.share-popup .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
  border: 0.5px solid #7e8486;
}

.share-popup .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border: 2px solid #a2a2a2;
}

.share-popup .MuiInputLabel-outlined {
  padding: 5px 10px;
  color: #a2a2a2;
  font-size: 12px;
}

.MuiAutocomplete-listbox {
  padding-bottom: 0;
}

.footer {
  background: #363636;
  padding: 19px 0;
  border-top: 1px solid #a2a2a2;
  display: flex;
  justify-content: space-between;
  height: 78px;
}

.inner-footer {
  margin: 0 auto;
  width: 1200px;
  color: #c7c7c7;
  font-size: 25px;
  display: flex;
  align-items: center;
}

.inner-footer .version {
  flex: 1;
}

.right-footer {
  color: #c7c7c7;
  font-size: 18px;
}

body .ant-select-dropdown {
  margin: 0 -10px;
  border-radius: 0;
}

.MuiAutocomplete-popper .MuiAutocomplete-paper {
  border-radius: 0;
}

.MuiAutocomplete-popper .MuiAutocomplete-option {
  font-size: 15px;
  border-bottom: 1px solid #a2a2a2;
  padding: 3px 5px;
  color: #3a4245;
}

.MuiAutocomplete-popper .MuiAutocomplete-option {
  padding: 5px;
  border-bottom: 1px solid #a2a2a2;
}

.manual-form {
  display: flex;
  flex-direction: column;
  padding-top: 15px;
  position: relative;
  flex: 1;
  z-index: 99;
}

.add-new-project {
  display: grid;
}

.create-project-top-bar {
  height: 34px;
}

.close-icon {
  float: right;
  color: #a2a2a2 !important;
}

.top-title {
  color: #3a4245;
  font-size: 16px;
  padding: 0 0 20px;
  font-family: "Roboto-Regular";
}

.MuiInputLabel-outlined {
  text-transform: capitalize;
}

.bottom-title {
  color: black !important;
  font-size: 16px;
}

.ant-modal-content {
  color: #3a4245 !important;
  border: transparent;
  font-size: 16px;
}

.ant-modal-confirm-body .ant-modal-confirm-content {
  font-size: 16px;
  color: #3a4245;
}

.ant-modal-title {
  color: #a2a2a2 !important;
}

.error-popup {
  max-width: 29%;
}

.error-popup .MuiOutlinedInput-notchedOutline {
  min-height: 300px;
}

.error-popup .MuiFormControl-root {
  padding: 0px 0px 10px 0px !important;
  width: 100%;
}

.error-popup-errors {
  color: #ff0000;
  font-size: 13px;
  margin-left: 5px;
}

.error-popup-error-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.error-popup-upload-data div {
  display: flex;
  justify-content: space-between;
}

.error-popup-upload-data {
  border-top: 0.75px solid #7e8486;
  border-bottom: 0.75px solid #7e8486;
  padding: 21px 0px;
  margin: 20px 0px;
}

.error-footer {
  padding-top: 10px;
}

.create-project-wrapper {
  min-width: 29%;
  max-width: 36%;
  position: relative;
  background-color: white;
}

.create-project-wrapper .modal-content {
  min-height: inherit;
  max-height: inherit;
}

.create-project-wrapper form {
  padding: 0 15px;
  max-height: calc(100vh - 223px);
  min-height: 388px;
  display: block;
  overflow: auto;
}

.create-project-wrapper .modal-content {
  padding-bottom: 40px;
}

.MuiFormHelperText-contained {
  color: #000000 !important;
  margin-top: 5px !important;
  font-size: 13px !important;
  margin-left: 0px !important;
  font-family: Roboto-Regular !important;
}

.ant-collapse-content>.ant-collapse-content-box {
  color: #8b8b8b !important;
  padding: 0 !important;
  text-indent: 30px;
  cursor: pointer;
}

.ant-collapse-content>.ant-collapse-content-box>div {
  background: rgba(255, 255, 255, 0.9);
}

.ant-collapse-content>.ant-collapse-content-box>div>hr {
  background-color: white;
}

.MuiBox-root {
  padding: 0 !important;
}

.tab-link {
  margin-right: 8px;
  margin-left: 12px;
  cursor: pointer;
}

.active {
  font-weight: 600;
  color: #fff;
}

.divider {
  width: 1px;
  height: 14px;
  content: "";
  color: #595964;
  background-color: #a2a2a2 !important;
  margin-top: 2px;
  position: absolute;
}

.layout-style .MuiGrid-spacing-xs-2 {
  margin: 0 -8px;
}

.MuiGrid-spacing-xs-2>.MuiGrid-item {
  padding: 50px !important;
}

.outer-wrapper .MuiGrid-spacing-xs-2 {
  width: calc(100% + 6px);
}

.add-keyword-wrapper {
  position: relative;
}

.add-keyword-wrapper .ant-select-selection:hover {
  border: 1px solid #a2a2a2;
}

.add-keyword {
  cursor: pointer;
  color: #00aebd;
  font-size: 16px;
  padding-bottom: 27px;
}

.keyword-notes {
  padding: 55px 10px 15px 0px;
}


.keyword-notes ul {
  list-style: none;
  padding-top: 7px;
  margin: 0;
}

.keyword-notes li,
.bulk-desc-body>ul>li {
  padding-left: 10px;
  position: relative;
  margin-bottom: 5px;
  font-size: 13px;
  list-style-type: disc !important;
  letter-spacing: 0;
  color: #000000;
}

.custom>.MuiFormHelperText-contained {
  color: #d66b6b !important;
}

.manual-upload-form {
  padding-top: 20px;
}

.bulk-upload-wrapper {
  padding: 0px 25px 20px 11px;
}

.halfWidth {
  width: 47%;
}

@media only screen and (max-width: 1250px) {
  .bulk-upload-wrapper {
    flex-direction: column;
  }

  .halfWidth {
    width: 100%;
  }

  .half-width-wrapper {
    flex-wrap: wrap;
  }
}

.view-errors {
  color: #00aebd;
}

.MuiButton-text.download-btn {
  padding: 4px 8px;
  background: #000 !important;
  color: #a2a2a2 !important;
}

.MuiFormLabel-root.Mui-focused {
  color: #a2a2a2 !important;
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #a2a2a2 !important;
}

.ant-table-tbody>tr>td {
  color: #000000;
  font-size: 13px;
}

.ant-table-tbody>tr>td a,
.ant-table-wrapper .ant-pagination-item-active a {
  color: #00aebd;
}

.ant-table-wrapper .ant-pagination-item-active {
  color: #00aebd;
}

.ant-table-thead>tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)>td,
.ant-table-tbody>tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)>td,
.ant-table-thead>tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)>td,
.ant-table-tbody>tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)>td {
  background: none !important;
}

.error-link,
.no-errors {
  margin-bottom: 3px;
}

.ant-table-pagination.ant-pagination {
  margin: 40px 0 0;
  padding-bottom: 15px;
}

.upload-wrapper .MuiButton-label,
.download-wrapper button {
  font-size: 14px;
  justify-content: center;
}

.download-wrapper .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-endAdornment {
  right: 9px;
  top: 11px;
  align-items: center;
  width: 20px;
  height: 20px;
  display: flex;
}

.bulk-upload-wrapper li {
  list-style-type: inherit;
}

.download-wrapper .MuiAutocomplete-clearIndicator {
  display: none;
}

.download-wrapper .MuiAutocomplete-root {
  width: 100%;
  padding: 0px !important;
}

.download-wrapper .MuiFormControl-root {
  width: 100%;
  padding: 0px !important;
}

.download-wrapper .MuiAutocomplete-popupIndicator {
  width: auto;
  min-width: auto;
}

.upload-wrapper .upload-label .MuiButton-label {
  justify-content: flex-start;
}

.upload-wrapper .upload-label .MuiButton-text {
  width: 100%;
  padding-left: 0;
  font-weight: normal;
}

.upload-wrapper .upload-label .select-csv span {
  font-size: 15px;
}

.upload-wrapper .upload-label .select-csv {
  padding: 0;
  height: 44.5px;
  border: none;
  color: #000000 !important;
  font-family: "Roboto-Regular";
}

.ant-upload-list-item-name,
.ant-upload-list-item-info .anticon-loading,
.ant-upload-list .ant-upload-list-item-info .anticon-paper-clip {
  color: #a2a2a2;
}

.MuiTab-wrapper,
.tab-link {
  color: #a2a2a2;
}

/* .tab-link.active {
  color: black;
} */
.PrivateTabIndicator-colorPrimary-144 {
  background-color: #a2a2a2 !important;
}

.upload-wrapper,
.download-wrapper {
  align-items: flex-end;
  display: flex;
  width: 100%;
  flex-direction: column;
}

.upload-label-wrapper {
  width: 100%;
}

.download-upload-wrapper {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
}

.upload-label {
  width: 100%;
}

.ant-upload.ant-upload-select {
  padding: 0 10px;
  width: 100%;
  height: 45px;
  margin-top: 8px;
}

.upload-btn {
  margin-left: 0 !important;
  height: 44px;
  width: 117px;
}

.select-csv {
  background: transparent !important;
}

.ant-upload-select-text {
  width: 67%;
  border: 0.5px solid #7e8486 !important;
  border-radius: 4px !important;
  font-size: 15px;
}

.MuiButton-label {
  text-transform: none !important;
  color: #a2a2a2;
}

.download-template-link a {
  padding: 0px 0 25px;
  cursor: pointer;
  color: #00aebd;
  font-size: 15px;
}

.MuiOutlinedInput-adornedEnd {
  padding: 0 !important;
}

.keywords-wrapper {
  display: flex;
}

.keywords-wrapper .MuiGrid-root {
  margin-right: 20px;
}

.ant-select {
  padding: 10px !important;
  width: 100%;
}

.ant-select-selection--multiple {
  min-height: 100px !important;
}

.MuiInputBase-multiline {
  min-height: 100px;
  margin-top: 9px !important;
}

.MuiButtonBase-root.Mui-disabled {
  cursor: no-drop !important;
  pointer-events: unset !important;
}

.MuiTableRow-root {
  background: #363636 !important;
}

.MuiTableCell-head {
  color: #a2a2a2 !important;
  background-color: #2e2e2e !important;
  border-bottom: transparent !important;
}

.MuiTableCell-body {
  border-bottom: 1px solid #a2a2a2 !important;
  color: #a2a2a2 !important;
}

.right-bottom-wrapper {
  min-height: 300px;
  background-image: url("../../public/images/Box-Background.png");
  background-size: cover;
  background-position: right bottom;
}

/* .logo {
  font-size: 32px;
  color: #c7c7c7;
  font-weight: 600;
  margin-right: 5px;
} */
.user-list {
  padding: 4px 15px 0px;
  margin-top: 6px;
  border-radius: 5px;
  min-height: 50px;
  color: #a2a2a2;
  border: 0.5px solid #7e8486;
  border-radius: 2px;
  height: 200.5px;
  /* min-width: 380.5px; */
  overflow-y: scroll;
}

.user-list li {
  border-bottom: 0.5px solid #d0d2d3;
  padding: 7px 0;
  display: flex;
  justify-content: space-between;
}

.ant-modal-close {
  color: #a2a2a2 !important;
}

.ant-popover-inner {
  background-color: #363636;
}

.ant-popover-title {
  color: #a2a2a2;
}

.ant-popover-inner-content {
  color: #fff;
}

.user-title {
  padding: 4px 0px;
  margin: 5px 0 0 0;
  color: #a2a2a2;
}

.error-popup .ant-modal-header {
  height: 26px;
  border: none;
}

.error-popup .MuiInputBase-multiline {
  min-height: 176.5px;
  align-items: flex-start;
  border: 0.5px solid #7e8486;
  border-radius: 2px;
}

.error-popup .MuiOutlinedInput-inputMultiline {
  min-height: 300px;
}

.error-popup .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: #a2a2a2;
}

.validation-text {
  font-size: 13px;
  color: #3a4245;
  text-align: center;
}

.error-popup .ant-modal-footer {
  border-top: none;
  padding: 0 30px 20px;
}

.error-popup .ant-modal-body {
  font-family: "Roboto-Regular";
  padding: 40px 73px 20px 54px;
}

.upload-data-value {
  font-size: 13px;
  max-width: 168px;
  padding-left: 20px;
}

.error-popup .ant-modal-footer>div {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

/* 
.error-popup .ant-modal-footer button {
  width: 135px;
  height: auto;
  padding: 5px 0;
  margin-top: -25px;
  min-height: 50px;
} */

.error-popup .ant-modal-footer button span {
  width: 100%;
  white-space: normal;
}

/* .error-popup .ant-modal-close-x {
  display: none;
} */

/* .error-popup .MuiSvgIcon-root.close-icon {
  position: absolute;
  right: 20px;
  top: 4px;
  cursor: pointer;
} */

.antd-menu {
  height: 100%;
  border-right: 0;
}

.layout-style {
  padding: 0px 48px;
}

.content-style {
  padding: 24px;
  margin: 0;
  padding-bottom: 11px;
}

.menu-style {
  margin: 16px 0;
  font-weight: 600;
}

.add-site-wrapper {
  width: 35%;
}

.project-btn {
  float: right;
}

.close-icon {
  color: #000;
}

.ant-empty-normal {
  margin: 0;
}

.no-data {
  margin-top: 25px;
}

.projects-heading {
  font-weight: 400;
  font-size: 22px;
}

.project-item {
  display: flex;
  justify-content: space-between;
  padding: 20px 20px 20px 35px !important;
}

.anticon-delete {
  cursor: pointer;
}

.error-msg {
  color: #d66b6b;
  text-align: left;
  display: block;
  font-size: 12px;
  padding-bottom: 20px;
}

.error-msg.hidden {
  display: none;
}

.share-popup .error-msg:not(:empty)+.MuiFormControl-root .MuiOutlinedInput-notchedOutline {
  border: 1px solid #d66b6b;
}

.share-popup .error-msg:not(:empty)+.MuiFormControl-root .MuiInputLabel-outlined {
  color: #d66b6b;
}

.bottom-bar {
  left: 0;
  width: 100%;
  height: 40px;
  position: absolute;
  bottom: 0;
}

.manual-submit-btn {
  align-self: center;
  margin-top: 20px;
}

.common-btn {
  color: #fff !important;
  background: #00aebd !important;
  font-size: 16px;
  font-weight: bold;
  font-family: Roboto-Bold;
}

.common-btn span {
  color: #fff !important;
  font-size: 16px;
  font-weight: bold;
  font-family: Roboto-Bold;
}

.MuiAutocomplete-paper>ul {
  padding: 0 !important;
}

.MuiAutocomplete-paper>ul>li:last-child {
  border-bottom: none;
}

.MuiAutocomplete-paper>ul>li:hover {
  color: #000;
}

.ant-table-thead>tr>th .ant-table-column-sorter .ant-table-column-sorter-inner .ant-table-column-sorter-up.on,
.ant-table-thead>tr>th .ant-table-column-sorter .ant-table-column-sorter-inner .ant-table-column-sorter-down.on {
  color: #F00053 !important;
}

.ant-table-thead>tr>th.ant-table-column-sort {
  background: #fff !important;
}

.ant-spin-dot-item {
  background-color: #a2a2a2;
}

.ant-table-body {
  width: 100%;
  overflow: auto;
}

.upload-history-wrapper .ant-table-thead>tr>th {
  word-wrap: normal;
  word-break: normal;
}

.rank-tracking-wrapper {
  font-family: Roboto-Regular;
}

.upload-history-container {
  display: flex;
  justify-content: center;
}

.filter-icon {
  font-size: 20px !important;
}

.filter-container {
  display: flex;
  align-items: flex-end;
}

.filter-container .MuiAutocomplete-inputRoot .MuiAutocomplete-input {
  margin-left: 18px !important;
}

.filter-container .MuiInputLabel-outlined.MuiInputLabel-shrink {
  transform: translate(25px, 0px) scale(0.75);
}

form .box-wrapper .MuiInputLabel-outlined div {
  height: 45px
}